var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Setting PPH23 List"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.checkPermission('add pph-23'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modalPph",modifiers:{"modalPph":true}}],staticClass:"btn waves-effect waves-float waves-primary btn-primary",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.cleanUpForm()}}},[_vm._v(" Create Setting PPH23 ")]):_vm._e(),_c('b-modal',{attrs:{"id":"modalPph","centered":"","title":"Create Setting PPH23","hide-footer":""}},[_c('ValidationObserver',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveItem.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Jenis Penghasilan * "),_c('span',{staticClass:"tooltip_hint",attrs:{"id":"hint-1"}},[_vm._v("?")])]),_c('validation-provider',{attrs:{"name":"Jenis Penghasilan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPph.income_type),expression:"formPph.income_type"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Masukan Content...","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPph.income_type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPph, "income_type", $event.target.value)}}}),_vm._l((_vm.validations.income_type),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])}),_c('b-tooltip',{attrs:{"target":"hint-1","triggers":"hover"}},[_vm._v(" Masukan Jenis Penghasilan Setting PPh23 ")])],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tarif (%) *")]),_c('validation-provider',{attrs:{"name":"Tarif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPph.rate),expression:"formPph.rate"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Masukan Tarif..","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPph.rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPph, "rate", $event.target.value)}}}),_vm._l((_vm.validations.rate),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary mt-2",attrs:{"variant":"primary","disabled":!_vm.formPph.income_type && !_vm.formPph.rate}},[_vm._v(" Save ")])])])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('form',{staticClass:"form-inline justify-content-end"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.keyword),expression:"filter.keyword"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Search: Name","aria-label":"Search"},domProps:{"value":(_vm.filter.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "keyword", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-outline-secondary ml-sm-2",attrs:{"disabled":!_vm.filter.keyword},on:{"click":function($event){_vm.filter.keyword = ''}}},[_vm._v(" Reset ")])])])])],1),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.pph,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * 10 + index + 1)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"warning"}},[(_vm.checkPermission('update pph-23'))?_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit Data'),expression:"'Edit Data'",modifiers:{"hover":true,"bottom":true}}],attrs:{"id":("invoice-row-" + (item.uuid) + "-info-icon"),"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.editItem(item)}}}):_vm._e()],1),_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"danger"}},[(_vm.checkPermission('delete pph-23'))?_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Delete Data'),expression:"'Delete Data'",modifiers:{"hover":true,"bottom":true}}],attrs:{"id":("invoice-row-" + (item.uuid) + "-preview-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteItem(item.uuid)}}}):_vm._e()],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-user-modal","title":"Edit Setting PPH23","centered":"","hide-footer":"","no-close-on-backdrop":""}},[_c('ValidationObserver',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateItem(_vm.formPayload)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Jenis Penghasilan * "),_c('span',{staticClass:"tooltip_hint",attrs:{"id":"hint-1"}},[_vm._v("?")])]),_c('validation-provider',{attrs:{"name":"Jenis Penghasilan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.income_type),expression:"formPayload.income_type"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Masukan Content...","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPayload.income_type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "income_type", $event.target.value)}}}),_vm._l((_vm.validations.income_type),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])}),_c('b-tooltip',{attrs:{"target":"hint-1","triggers":"hover"}},[_vm._v(" Masukan Jenis Penghasilan Setting PPh23 ")])],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tarif (%) *")]),_c('validation-provider',{attrs:{"name":"Tarif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayload.rate),expression:"formPayload.rate"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Masukan Tarif..","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formPayload.rate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayload, "rate", $event.target.value)}}}),_vm._l((_vm.validations.rate),function(validation,index){return _c('span',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary mt-2",attrs:{"variant":"primary"}},[_vm._v(" Save ")])])])],1),(_vm.result.total > 0)?_c('div',{staticClass:"m-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-1"},[_c('small',[_vm._v("Showing "+_vm._s(_vm.result.from)+" to "+_vm._s(_vm.result.to)+" from "+_vm._s(_vm.result.total))])]),_c('div',{staticClass:"col"},[_c('pagination',{attrs:{"data":_vm.result,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }