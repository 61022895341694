<template>
	<b-card title="Setting PPH23 List">
		<b-row class="mb-2">
			<b-col cols="12" md="6">
				<b-button
					class="btn waves-effect waves-float waves-primary btn-primary"
					variant="primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.modalPph
					@click="cleanUpForm()"
					v-if="checkPermission('add pph-23')"
				>
					Create Setting PPH23
				</b-button>
				<b-modal
					id="modalPph"
					centered
					title="Create Setting PPH23"
					hide-footer
				>
					<ValidationObserver>
						<form @submit.prevent="saveItem">
							<div class="form-group">
								<label>Jenis Penghasilan * <span class="tooltip_hint" id="hint-1">?</span></label>
								<validation-provider
									name="Jenis Penghasilan"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control" 
										:class="classes"
										placeholder="Masukan Content..." 
										v-model="formPph.income_type"
										:state="errors.length > 0 ? false:null"
									>
									<span
										v-for="(validation, index) in validations.income_type"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</span>
								</validation-provider>
								<b-tooltip target="hint-1" triggers="hover">
									Masukan Jenis Penghasilan Setting PPh23
								</b-tooltip>
							</div>
							<div class="form-group">
								<div class="form-group">
									<label>Tarif (%) *</label>
									<validation-provider
										name="Tarif"
										rules="required"
										v-slot="{ errors, classes }"
									>
										<input 
											type="text" 
											class="form-control"
											:class="classes"
											v-model="formPph.rate"
											placeholder="Masukan Tarif.."
											:state="errors.length > 0 ? false:null"
										>
										<span
											v-for="(validation, index) in validations.rate"
											:key="`errorName${index}`"
											class="text-danger"
										>{{ validation }}</span>
									</validation-provider>
								</div>
							</div>
							<button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-2"
								:disabled="!formPph.income_type && !formPph.rate"
							>
								Save
							</button>
						</form>
					</ValidationObserver>
				</b-modal>
			</b-col>
			<b-col cols="12" md="6">
				<form class="form-inline justify-content-end">
					<div class="d-flex">
						<div class="position-relative">
							<input 
								type="search"
								class="form-control"
								v-model="filter.keyword" 
								placeholder="Search: Name" 
								aria-label="Search"
							>
						</div>
						<button 
							:disabled="!filter.keyword" 
							@click="filter.keyword = ''" 
							class="btn btn-outline-secondary ml-sm-2"
						>
							Reset
						</button>
					</div>
				</form>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover
			:items="pph" 
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>

			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<b-badge 
						variant="warning"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-info-icon`"
							icon="EditIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Edit Data'"
							@click="editItem(item)"
							v-if="checkPermission('update pph-23')"
						/>
					</b-badge>
					
					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-preview-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="deleteItem(item.uuid)"
							v-if="checkPermission('delete pph-23')"
						/>
					</b-badge>

				</div>
			</template>
		</b-table>
		<b-modal
			id="form-user-modal"
			title="Edit Setting PPH23"
			centered
			hide-footer
			no-close-on-backdrop
		>
			<ValidationObserver>
				<form @submit.prevent="updateItem(formPayload)">
					<div class="form-group">
						<label>Jenis Penghasilan * <span class="tooltip_hint" id="hint-1">?</span></label>
						<validation-provider
							name="Jenis Penghasilan"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text"
								class="form-control" 
								:class="classes"
								placeholder="Masukan Content..." 
								v-model="formPayload.income_type"
								:state="errors.length > 0 ? false:null"
							>
							<span
								v-for="(validation, index) in validations.income_type"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</span>
						</validation-provider>
						<b-tooltip target="hint-1" triggers="hover">
							Masukan Jenis Penghasilan Setting PPh23
						</b-tooltip>
					</div>
					<div class="form-group">
						<div class="form-group">
							<label>Tarif (%) *</label>
							<validation-provider
								name="Tarif"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text" 
									class="form-control"
									:class="classes"
									v-model="formPayload.rate"
									placeholder="Masukan Tarif.."
									:state="errors.length > 0 ? false:null"
								>
								<span
									v-for="(validation, index) in validations.rate"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</span>
							</validation-provider>
						</div>
					</div>
					<button
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary mt-2"
					>
						Save
					</button>
				</form>
			</ValidationObserver>
		</b-modal>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	VBModal,
	BButton,
	BSpinner,
	BBadge,
	BCol,
	BRow,
	VBTooltip
} from 'bootstrap-vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BCol,
		BRow,
		ValidationProvider,
		ValidationObserver,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
		Ripple,
	},
	setup() {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},

	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no', 'income_type', 'rate', 'actions'],
			pph: [],
			filter: {
				keyword: ''
			},
			formPph: {
				income_type: '',
				rate: ''
			},
			formPayload: Object,
			validations:''
		}
	},

	computed: {
		rows() {
			return this.pph.length
		}
	},

	methods: {
		cleanUpForm() {
			this.formPph = {
				income_type: '',
				rate: ''
			}
		},
		getData(page) { //page = 1
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/pph-23', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.pph = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		saveItem() {
			this.$http.post('admin/pph-23', this.formPph)
			.then(response => {
				this.getData()
				this.cleanUpForm()
				successNotification(this, 'Success', 'Setting PPH23 Sukses Dibuat!')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Jenis Penghasilan', this.validations.income_type.join(' '))
					errorNotification(this, 'Tarif', this.validations.rate.join(' '))
				}
			})
			this.$nextTick(() => {
				this.$bvModal.hide('modalPph')
			})
		},
		updateItem(item) {
			item._method = 'PATCH'
			this.$http.post('admin/pph-23/' + item.uuid, item)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Setting PPH23 Berhasil Diedit!')
				this.$bvModal.hide('form-user-modal')
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Jenis Penghasilan', this.validations.income_type.join(' '))
					errorNotification(this, 'Tarif', this.validations.rate.join(' '))
				}
			})
		},
		editItem(item) {
			this.cleanUpForm();
			this.formPayload = {
				uuid: item.uuid,
				income_type: item.income_type,
				rate: item.rate,
			}
			this.$bvModal.show('form-user-modal')
		
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus data Progressive ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/pph-23/' + item)
					this.getData()
					this.pph.splice(item,1)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Setting PPH23 Sukses Dihapus!')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Jenis Penghasilan', this.validations.income_type.join(' '))
					errorNotification(this, 'Tarif', this.validations.rate.join(' '))
				}
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>

<style scoped>
.tooltip_hint {
	border-radius: 50%;
	background: #000;
	color: #fff;
	padding: 0px 4px;
}
</style>